import { createApp, h } from 'vue'
import VCalendar from 'v-calendar';
import App from './App.vue'
import {router} from './router'
import { gsap, ScrollTrigger } from 'gsap/all';
import "./scss/styles.scss"


gsap.registerPlugin(ScrollTrigger);

const app = createApp({
	render: () => h(App)
}).use(router)

app.use(VCalendar, {})

app.mount('#app')

// If we are not in Cordova, manually trigger the deviceready event
const isCordovaApp = (typeof window.cordova !== 'undefined')
if (!isCordovaApp) {
	document.dispatchEvent(new CustomEvent('deviceready', {}))
}


