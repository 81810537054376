import {createRouter, createWebHistory} from 'vue-router'

const Welcome = () => import('./views/connexionStory.vue')
const Login = () => import('./views/connexionPage.vue')
const Home = () => import('./views/accueil.vue')
const Producers = () => import('./views/carte.vue')
const infoReservation = () => import('./views/informationReservation.vue')
const comments = () => import('./views/comments.vue')
const newAccount = () => import ('./views/newAccount.vue')
const mdpForgot = () => import ('./views/mdpForgot.vue')
const profil = () => import ('./views/profil.vue')
const Reservation = () => import ('./views/reservations.vue')
const carteMembre = () => import ('./views/carteMembre.vue')
const vehicule = () => import ('./views/vehicule.vue')
const annulerReservation = () => import ('./views/annulerReservation.vue')
const annulerConfirmation = () => import ('./views/annulerConfirmation.vue')
const infoPersonnel = () => import ('./views/infoPersonnel.vue')
const offers = () => import ('./views/offers.vue')
const favoris = () => import ('./views/favoris.vue')
const favorisList = () => import ('./views/favorisList.vue')
const itineraire = () => import ('./views/itineraire.vue')
const reservationConfirmation = () => import ('./views/reservationConfirmation.vue')
const reservationModifier = () => import ('./views/reservationModifier.vue')
const reservationModifierConfirmation = () => import ('./views/reservationModifierConfirmation.vue')

export const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'welcome',
            component: Welcome
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {transition: 'fade'}
        },
        {
            path: '/producers',
            name: 'producers',
            component: Producers,
            meta: {transition: 'fade'}
        },
        {
            path: '/inforeservations',
            name: 'inforeservations',
            component: infoReservation,
            meta: { transition: 'slideRight' }
        },
        {
            path: '/comments',
            name: 'comments',
            component: comments,
            meta: { transition: 'slideLeft' }
        },
        {
            path: '/newAccount',
            name: 'newAccount',
            component: newAccount
        },
        {
            path: '/mdpForgot',
            name: 'mdpForgot',
            component: mdpForgot
        },
        {
            path: '/profil',
            name: 'profil',
            component: profil,
            meta: {transition: 'fade'}
        },
        {
            path: '/reservation',
            name: 'reservation',
            component: Reservation,
            meta: {transition: 'fade'}
        },
        {
            path: '/carteMembre',
            name: 'carteMembre',
            component: carteMembre,
            meta: {transition: 'slideRight'}
        },
        {
            path: '/vehicule',
            name: 'vehicule',
            component: vehicule,
            meta: {transition: 'slideRight'}
        },
        {
            path: '/annuler',
            name: 'annulerReservation',
            component: annulerReservation,
            meta: {transition: 'fade'}
        },
        {
            path: '/confirmation',
            name: 'confirmationAnnulation',
            component: annulerConfirmation,
            meta: {transition: 'fade'}
        },
        {
            path: '/infoPersonnel',
            name: 'infoPersonnel',
            component: infoPersonnel,
            meta: {transition: 'slideRight'}
        },
        {
            path: '/offers',
            name: 'offers',
            component: offers,
            meta: {transition: 'slideRight'}
        },
        {
            path: '/favoris',
            name: 'favoris',
            component: favoris,
            meta: {transition: 'fade'}
        },
        {
            path: '/favorisList',
            name: 'favorisList',
            component: favorisList,
            meta: { transition: 'slideLeft'}
        },
        {
            path: '/itineraire',
            name: 'itineraire',
            component: itineraire,
            meta: {transition: 'fade'}
        },
        {
            path: '/reservationConfirmation',
            name: 'reservationConfirmation',
            component: reservationConfirmation,
            meta: {transition: 'fade'}
        },
        {
            path: '/reservationModifier',
            name: 'reservationModifier',
            component: reservationModifier,
            meta: {transition: 'slideRight'}
        },
        {
            path: '/reservationModifierConfirmation',
            name: 'reservationModifierConfirmation',
            component: reservationModifierConfirmation,
            meta: {transition: 'fade'}
        }
    ]
})
