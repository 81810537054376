<template>
  <router-view v-slot="{ Component, route }">
  <transition :name="route.meta.transition" appear mode="out-in">
    <div :key="route.path">  
      <component :is="Component"></component>
    </div>
  </transition>
</router-view>

</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import './scss/styles.scss';

.slideRight-enter-active,
.slideRight-leave-active {
  transition:  opacity 300ms ease, transform 300ms ease;
}

.slideRight-enter-from {
  transform: translateX(100%);
}
.slideRight-leave-to {
 opacity: 0;
}

.slideLeft-leave-active,
.slideLeft-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

.slideLeft-enter-from {
  transform: translateX(100%);
}
.slideLeft-leave-to {
  opacity: 0;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 125ms ease
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}

</style>
